import React, { useState, useEffect, useCallback } from 'react';
import "./styles.css";
import { TRACKS } from "./constants/tracks";

const audioTune = new Audio();
audioTune.loop = false;

let timeoutId: number | undefined;

// const bc = new BroadcastChannel('loop_channel');
//
// bc.onmessage = function (e) {
//     const command = e.data.cmd;
//     switch (command) {
//         case "timeToSound":
//             audioTune.pause();
//             audioTune.currentTime = 0;
//             audioTune.src = TRACKS[12];
//             audioTune.load();
//             audioTune.play();
//             break;
//         default:
//             console.log('App: Unknown command', e);
//     }
// }

// function startLoop() {
//     bc.postMessage({ cmd: 'start' });
// }

const checkTime = ():boolean => {
    const now = new Date();
    const start = new Date(now);
    start.setHours(7,30,0);
    const end = new Date(now);
    end.setHours(20,0,0);

    return now >= start && now < end;
}

export default function App() {
    const [started, setStarted] = useState<boolean>(false);
    const [delay, setDelay] = useState<number>(60); // seconds
    const [soundIndex, setSoundIndex] = useState<number>(0);

    const playSound = () => {
        audioTune.play();
    }

    const pauseSound = () => {
        audioTune.pause();
    }

    const stopSound = () => {
        audioTune.pause();
        audioTune.currentTime = 0;
        clearTimeout(timeoutId);
        setStarted(false);
    }

    const prevSound = () => {
        setSoundIndex((currentIndex) => currentIndex === 0 ? TRACKS.length - 1 : currentIndex - 1);
    }

    const nextSound = useCallback(() => {
        setSoundIndex((currentIndex) => currentIndex === TRACKS.length - 1 ? 0 : currentIndex + 1);
    }, []);

    const decDelay = useCallback(() => {
        setDelay((current) => current > 10 ? current - 10 : current);
    }, []);

    const incDelay = () => {
        setDelay((current) => current + 10);
    }

    const autoPlay = () => {
        if (!checkTime() && !window.confirm('Ночное время! Запустить одну запись?')) return;
        stopSound();
        setStarted(true);
        audioTune.onloadeddata = () => {
            playSound();
        }
        audioTune.onended = () => {
            timeoutId = setTimeout(nextSound, delay * 1000) as unknown as number;
        }
        audioTune.src = TRACKS[soundIndex];
        audioTune.load();
    }

    useEffect(() => {
        if (!started) return;
        autoPlay();
    }, [started, soundIndex, nextSound]);

    return (
        <div className="App">
            <div className='panel'>
                <button onClick={decDelay}>-</button>
                <span>
                    Задержка: {delay}
                </span>
                <button onClick={incDelay}>+</button>
            </div>
            <div className='panel'>
                <button onClick={playSound}>⏵︎</button>
                <button onClick={pauseSound}>⏸︎</button>
                <button onClick={stopSound}>⏹︎</button>
            </div>
            <div className='panel'>{TRACKS[soundIndex].replace('audio/', '')}</div>
            <div className='panel'>
                <button onClick={prevSound}>⏮︎</button>
                <button onClick={nextSound}>⏭︎</button>
            </div>
            <div className='panel'>
                {started
                    ? <button onClick={stopSound} className='stopButton'>Стоп</button>
                    : <button onClick={autoPlay} className='startButton'>Пуск</button>
                }
            </div>
        </div>
    );
}
