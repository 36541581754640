import { StrictMode } from "react";
import * as ReactDOMClient from "react-dom/client";

import App from "./App";
import React from "react";

if ('serviceWorker' in navigator) {
    window.addEventListener('load', function() {
        navigator.serviceWorker.register('/service-worker.js').then(function() {
            console.log('ServiceWorker registration successful');
        }, function(err) {
            console.log('ServiceWorker registration failed: ', err);
        });
    });
}
else {
    console.log('no service worker in navigator');
}

const rootElement = document.getElementById("root");
const root = ReactDOMClient.createRoot(rootElement as Element);

root.render(
    <StrictMode>
        <App />
    </StrictMode>
);
