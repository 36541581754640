export const TRACKS = [
    'audio/Тетеревятник/teterevyatnik-golos-1.mp3',
    'audio/Тетеревятник/teterevyatnik-golos-5.mp3',
    'audio/Тетеревятник/teterevyatnik-golos-6.mp3',
    'audio/Тетеревятник/teterevyatnik-golos-11.mp3',
    'audio/Тетеревятник/teterevyatnik-golos-16.mp3',
    'audio/Перепелятник/perepelyatnik-golos-4.mp3',
    'audio/Перепелятник/perepelyatnik-golos-12.mp3',
    'audio/Сапсан/sapsan-golos-1.mp3',
    'audio/Сапсан/sapsan-golos-3.mp3',
    'audio/Балобан/baloban-golos-1.mp3',
    'audio/Балобан/baloban-golos-3.mp3',
    'audio/Балобан/baloban-golos-4.mp3',
    'audio/Балобан/baloban-golos-5.mp3',
    'audio/Красный_коршун/krasnyy-korshun-golos-1.mp3',
    'audio/Конюк/obyknovennyy-kanyuk-golos-1.mp3',
    'audio/Конюк/obyknovennyy-kanyuk-golos-2.mp3',
    'audio/Конюк/obyknovennyy-kanyuk-golos-5.mp3',
];